import React, { useContext, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Row,
  Accordion,
  Card,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap"
import impactban from "../images/ourImpact/banner.png"
import ci1 from "../images/ourImpact/soc1.png"
import ci2 from "../images/ourImpact/soc2.png"
import ci3 from "../images/ourImpact/soc3.png"
import ci4 from "../images/ourImpact/soc4.png"
import Slider from "react-slick"
import left from "../images/left.svg"
import right from "../images/right.svg"
import alumPdf from "../../static/assets/alumni-impact-report_2009-2021.pdf"
// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}

const OurImpact = () => {
  const soc = useRef()

  const settingsSoc = {
    dots: true,
    dotsClass: "slick-dots fel-indi laf-fel-indi",
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
        },
      },
    ],
  }

  const socPrevious = () => {
    soc.current.slickPrev()
  }
  const socNext = () => {
    soc.current.slickNext()
  }
  return (
    <Layout pageName="Our Impact">
      <SEO title="Our Impact" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <img className="w-100" src={impactban} />
        </div>
        <div className="col-lg-6 px-0 bg-sec align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p className="ed-title">The Journey</p>
              <p className="imp-head mb-lg-3 mb-3">
                In pursuit of educational equity
              </p>
              <p className="fi-txt imp-txt">
                Over the last 13 years, we’ve set up a Fellowship that has
                proven impact for accelerating both Student learning and Fellow
                leadership development. Our 3800+ Alumni who have completed the
                Fellowship serve at all levels of the educational ecosystem,
                from leading change in classrooms to leading change in policy.
                Currently, 970 Fellows, 32000 Students, 270 under-resourced
                schools, 9100 Student Alumni.
              </p>
              <p className="fi-txt imp-txt mb-lg-3 mb-4">
                We have built a national focus on repairing India’s educational
                crisis through our Innovation Cells. Through social and print
                media- we have garnered the support of thousands of people, all
                of whom are invested in the vision of an excellent education for
                all children.
              </p>
              <p>
                <a
                  href={alumPdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="butn butn-main butn-sp butn-sm-sp"
                >
                  View Impact Report
                </a>
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Container>
        <p className="fi-head text-center pt-5">Teach For India in 13 years </p>

        <Accordion className="faq-acc oi-acc pb-4">
          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="0">
                <div className="oi-item">
                  <div className="oi-year">2006</div>
                  <p className="ed-title">The Inception</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  17 years after starting the Akanksha Foundation, Shaheen felt
                  compelled to address the issue of educational inequity at
                  scale. As she thought through the solution, she believed that
                  the answer lay in a people’s movement that needed to come
                  together to provide every child in India an excellent
                  education.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="1">
                <div className="oi-item">
                  <div className="oi-year">2007</div>
                  <p className="ed-title">The Founding</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Shaheen met with Wendy Kopp, the Founder of Teach For America
                  and was struck by the idea of leadership being at the core of
                  the solution. Seeking to adapt the Teach For America model to
                  the Indian context, we engaged with a number of stakeholders
                  within the government, at academic institutions and at
                  corporations and were encouraged by the favourable response we
                  received. After a 12 week Study in collaboration with McKinsey
                  & Company- a plan to place the first cohort of Teach For India
                  was made.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="2">
                <div className="oi-item">
                  <div className="oi-year">2009</div>
                  <p className="ed-title">
                    Beginning in Mumbai & Pune with the First Cohort{" "}
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Teach For India welcomed its first cohort, the Class of 2009,
                  to the Fellowship. The 87 “niners” in Mumbai and Pune- our two
                  cities of operation, formed the beginning of what Shaheen
                  hoped would become a nationwide movement of diverse leaders.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="3">
                <div className="oi-item">
                  <div className="oi-year">2011</div>
                  <p className="ed-title">
                    The Beginning of the Alumni Movement
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Our Niners graduated from the Fellowship and became the first
                  cohort in our Alumni movement. Through their work as Alumni,
                  the hope was that they would become lifelong leaders for
                  equity. Our first cohort of Alumni went into teaching, teacher
                  training, school leadership, and government policy. We also
                  began expanding from just two cities of operation to 7 cities
                  across India.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="4">
                <div className="oi-item">
                  <div className="oi-year">2012</div>
                  <p className="ed-title">Beginning in Chennai and Hyderabad</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Teach For India Chennai and Hyderabad are launched as sites of
                  operations.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="5">
                <div className="oi-item">
                  <div className="oi-year">2012</div>
                  <p className="ed-title">
                    inspirED hosted its first series of conferences in Delhi,
                    Mumbai and Pune.
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  This was an annual, world-class education conference,
                  organized by Teach For India. The conference brought together
                  educators and leaders to engage in a dialogue on the
                  challenges of equity in education and its solutions. In 2014,
                  it saw 400 participants, 85 organisations and over 30
                  speakers, with Arvind Kejriwal, the CM of Delhi as the keynote
                  speaker.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="6">
                <div className="oi-item">
                  <div className="oi-year">2014</div>
                  <p className="ed-title">The Broadway Musical, Maya</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  A partnership between Teach For India Students and Artists on
                  Broadway took 30 children on a life-changing journey of
                  self-discovery and of finding their values and their brightest
                  light. It culminated in a spectacular Broadway Musical with a
                  full house and standing ovations for 11 shows.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="7">
                <div className="oi-item">
                  <div className="oi-year">2014</div>
                  <p className="ed-title">Beginning in Ahmedabad</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="7">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Teach For India Ahmedabad began as a site of operations. We
                  published a book on our five year journey and genesis called-
                  Redrawing India.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="8">
                <div className="oi-item">
                  <div className="oi-year">2015</div>
                  <p className="ed-title">Beginning in Bengaluru</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="8">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Teach For India Bengaluru began as a site of operations.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="9">
                <div className="oi-item">
                  <div className="oi-year">2015</div>
                  <p className="ed-title">
                    InnovatED identified it’s first cohort of 12 Entrepreneurs.
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="9">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  InnovatED, a nationwide incubator program for early-stage
                  educational entrepreneurs from the Teach For India network,
                  identified it’s first cohort of 12 Entrepreneurs.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="10">
                <div className="oi-item">
                  <div className="oi-year">2016</div>
                  <p className="ed-title">Firki was launched.</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="10">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Firki was launched as India’s first and only online teacher
                  training portal: a world-class, open-source, blended learning
                  program for teachers across India to use and transform their
                  teaching methods. The Firki Mobile App went live in May, 2018.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="11">
                <div className="oi-item">
                  <div className="oi-year">2017</div>
                  <p className="ed-title">TFIx graduated its first cohort.</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="11">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  TFIx, a year long incubator program for passionate
                  entrepreneurs who are willing to adapt Teach For India’s model
                  to their context and region- graduated its first cohort.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="12">
                <div className="oi-item">
                  <div className="oi-year">2018</div>
                  <p className="ed-title">
                    National Summit of the Kids Education Revolution
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="12">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Teach For India hosted the very first National Summit of the
                  Kids Education Revolution, in Mumbai at the American School,
                  Bombay.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="13">
                <div className="oi-item">
                  <div className="oi-year">2019</div>
                  <p className="ed-title">
                    Teach For India launched Grey Sunshine
                  </p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="13">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  A book on its 10 year journey, alongside a grand stage
                  musical- ‘The Greatest Show on Earth’ where Students depicted
                  their struggles in the education system through the metaphor
                  of a circus. The show was staged across Mumbai, Pune and New
                  Delhi.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="oi-card">
            <Card.Header>
              <ContextAwareToggle eventKey="14">
                <div className="oi-item">
                  <div className="oi-year">2020</div>
                  <p className="ed-title">Don&apos;t stop learning</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="14">
              <Card.Body className="content">
                <p className="fi-txt text-left">
                  Since COVID-19 devastated the world in March 2020, our
                  community has worked to support each other emotionally and
                  mentally as much as possible. Through our
                  #Don&apos;tStopLearning campaign we raised funds to procure
                  gadgets for our children. We also ran a crowdfunding campaign
                  to ensure our communities have access to essentials.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
      <Container className="pt-5">
        <p className="fi-head text-center max-60 mx-auto max-sm-100 mb-lg-5">
          Setting children up for a different life path
        </p>
        <Row>
          <div className="col-lg-6">
            <iframe
              width="100%"
              id="youtubeplayer"
              frameBorder="0"
              src="https://www.youtube-nocookie.com/embed/hXMcpb0okXA"
              title="Teach For India"
              className="d-none d-lg-block"
            ></iframe>
          </div>
          <div className="col-lg-6">
            <iframe
              width="100%"
              id="youtubeplayer2"
              frameBorder="0"
              src="https://www.youtube-nocookie.com/embed/2VLhyL3hJUw"
              title="Teach For India"
              className="d-none d-lg-block"
            ></iframe>
          </div>
        </Row>
        <div className="pb-3">
          <iframe
            width="100%"
            id="youtubeplayermob"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/hXMcpb0okXA"
            title="Teach For India"
            className="d-block d-lg-none"
          ></iframe>
        </div>
        <div>
          <iframe
            width="100%"
            id="youtubeplayermob2"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/2VLhyL3hJUw"
            title="Teach For India"
            className="d-block d-lg-none"
          ></iframe>
        </div>
      </Container>
      <Container className="py-5">
        <p className="text-center wn-title">Academic Impact </p>
        <Accordion className="cpp-acc acad" defaultActiveKey="10">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="0">
                <Row className="py-3">
                  <div className="col-lg-5 pr-3  ">
                    <p className="wn-title">Math</p>
                  </div>
                  <div className="col-lg-7 spp-cont">
                    <p className="fi-txt">
                      Our Students are learning to recall and apply knowledge
                      and skills appropriate to their level. This has led to a
                      36% grade-level mastery of Math at the end of the year
                      versus 20% at the beginning of the year in 2018-19.
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="1">
                <Row className="py-3">
                  <div className="col-lg-5 pr-3  ">
                    <p className="wn-title">Reading Comprehension</p>
                  </div>
                  <div className="col-lg-7 spp-cont">
                    <p className="fi-txt">
                      On average, Teach For India Students showed a 0.6 year of
                      growth in their reading level in 2018-19, which means they
                      have achieved a higher level of their reading ability.
                      This is especially important as many of them are below
                      grade level at the time of Teach For India&apos;s
                      intervention, and our Fellows have to accelerate their
                      learning in order for them to be at par with peers from
                      the same grade.
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="2">
                <Row className="py-3">
                  <div className="col-lg-5 pr-3  ">
                    <p className="wn-title">Values and Mindset </p>
                  </div>
                  <div className="col-lg-7 spp-cont">
                    <p className="fi-txt">
                      We believe that an excellent education is a balance of
                      academics and character development through strengthening
                      values and mindsets. Our Students are passionate and
                      joyful, and can evaluate and create challenging content.
                      They are able to independently demonstrate class values,
                      both in and out of class, and are able to leverage
                      opportunities in the community and world around them.
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>

          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="3">
                <Row className="py-3">
                  <div className="col-lg-5 pr-3  ">
                    <p className="wn-title">Access and Exposure</p>
                  </div>
                  <div className="col-lg-7 spp-cont">
                    <p className="fi-txt">
                      Each year, our Students participate in activities like
                      Model United Nations, and have also participated and been
                      awarded at various Olympiads and other competitions. They
                      participate in extracurricular fests, do slam poetry, and
                      go on residential leadership camps. Many of them even take
                      part in nationwide sporting events in sports like
                      Football, Ultimate Frisbee and Athletics.
                    </p>
                    <p className="fi-txt">
                      As leaders, they have facilitated Teach For All
                      educational conferences. They have also led Design For
                      Change conferences in Rome. In fact, Teach For India
                      Students have consistently reached the top ten list of
                      global Design For Change projects. Many of them have
                      founded their own projects/organisations for change as
                      part of the Kids Education Revolution programme, liaising
                      directly with policymakers and educators.
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>
        </Accordion>
      </Container>
      <Container>
        <p className="text-center wn-title">Stories of Change</p>
        <Slider {...settingsSoc} className="laf-fel" ref={soc}>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">
                Rehan and Basit, leading change for their community
              </p>
              <p className="fel-car-txt">
                Rehan and Basit- two young boys aged 12, living in a community
                on the site of Ahmedabad’s largest dumping ground. They’ve
                scaled the mountain of garbage, collected toxic samples, sent
                them to the press, got published, and lobbied the government
                alongside members of the community. Their efforts have even
                taken them to lead sessions at the global Design for Change
                summit in Rome.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/city/ahmedabad/danilimda-to-rome-city-kids-make-it-to-design-for-change-summit/articleshow/72203737.cms"
                >
                  Read the entire story
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={ci1} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">
                From a Teach For India classroom to a college in the US
              </p>
              <p className="fel-car-txt">
                Priyanka, who began her academic journey in a Teach For India
                classroom, overcame her crippling shyness to star in a Teach For
                India produced Broadway musical, became a poet and a speaker-
                and is now pursuing a Masters Degree at Franklin & Marshall
                College in the US, after successfully completing her Bachelors
                at UWC Singapore.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/city/mumbai/teach-for-india-is-not-just-an-organisation-its-a-movement/articleshow/70970551.cms"
                >
                  Read the entire story
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={ci2} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">
                The Umang theater group brings change to Pune’s streets
              </p>
              <p className="fel-car-txt">
                Mahesh, alongside 25 other Students from Teach For India
                classrooms in Pune, have founded the Umang Theater Group, a
                space for free expression and art. He has also spoken at global
                conferences on behalf of Teach For India. In 2019, they starred
                in ‘The Greatest Show on Earth’- a musical conceptualised by
                Teach For India where the Students told moving stories from
                their real life through the metaphor of a circus.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/city/pune/city-students-take-to-street-plays-for-a-social-cause/articleshow/65241030.cms"
                >
                  Read the entire story
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={ci3} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">
                The Greatest Show on Earth - Sakshi speaks
              </p>
              <p className="fel-car-txt">
                ”To me, The Greatest Show on Earth is an opportunity to
                encourage and inspire everyone to believe and create change of
                their own. The one thing that makes this show really important
                is that we are trying to become the voice of millions of kids in
                India who struggle every day and face countless problems. I hope
                audiences leave inspired to step up and contribute towards
                changing the education system.”
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/watch/live/?v=2559850564342753&ref=watch_permalink"
                >
                  Read the entire story
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={ci4} className="w-100" />
            </div>
          </div>
        </Slider>
        <div className="laf-gal-indi car-indi d-flex d-lg-none my-4">
          <div className="left" onClick={socPrevious}>
            <img src={left} />
          </div>
          <div className="right" onClick={socNext}>
            <img src={right} />
          </div>
        </div>
      </Container>
      <Container>
        <Row className="pt-5 mt-5">
          <div className="col-lg-12 text-center">
            <p className="fi-head">Fuelling Systemic change </p>
            <div className="max-80 mx-auto">
              <p className="fi-txt mb-0">
                Fuelled by their learning and dedication to the mission during
                the Fellowship, our Fellows join our Alumni community, driving
                long term systemic change towards educational equity. Nearly 67
                percent of our Alumni are working in positions of influence
                across sectors in the education ecosystem: in schools,
                nonprofits, corporations, and within national and local
                government bodies. Since 2015, Teach For India Fellows and
                Alumni have consistently ranked amongst the top 10 teachers who
                participate in the CENTA Teaching Olympiad across India. More
                than 20% of our Alumni work in the Private Sector and over 150
                Alumni have completed advanced degrees in public policy,
                business and social entrepreneurship at the most selective and
                prestigious global institutions.
              </p>
            </div>
            <p className="my-5">
              <a
                href="/life-as-alumni"
                className="butn butn-main butn-sp butn-sm-sp"
              >
                About Alumni
              </a>
            </p>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default OurImpact
